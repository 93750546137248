@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@400;600;800&family=Sora:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Sofia Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Sora', sans-serif;

}

.main {
  display: block;
}

.mainSmall {
  display: none;
}

@media only screen and (max-width: 900px) {
  .main {
    display: none;
  }
  
  .mainSmall {
    background-color: #101010;
    color: white;
    font-size: 32px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}