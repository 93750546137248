

html,
body,
body > div { /* the react root */
  margin: 0;
  padding: 0;
  height: 100%;
  background: #ffffff;
}

.fc  {
    font-family: 'Sofia Sans', sans-serif;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: #ffdeb4;
}

.fc .fc-event-main, .fc .fc-button-primary {
    font-family: 'Sora', sans-serif;
    font-style: normal !important;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.moduleBtn {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px; 
    letter-spacing: 0.04em; 
    border: none;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    border-radius: 9px;
    padding: 5px 40px;
    margin-top: 25px;
    color: #fff;
    background-clip: text;
    background: #101010;
}

.moduleBtn:hover {
    box-shadow: 0px 0px 15px rgba(66, 66, 66, 0.57);
    background: #101010;
}

.progressBtn {
  font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 36px; 
    letter-spacing: 0.04em; 
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 9px;
    padding: 5px 40px;
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-clip: text;
    background: #015616;
}

.progressBtn:hover {
  box-shadow: 0px 0px 15px rgba(66, 66, 66, 0.57);
  background: #01771e;
}

.cancelBtn {
  font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 36px; 
    letter-spacing: 0.04em; 
    border: none;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    border-radius: 9px;
    padding: 5px 40px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-clip: text;
    background: #585858;
}

.cancelBtn:hover {
  box-shadow: 0px 0px 15px rgba(66, 66, 66, 0.57);
  background: #3a3a3a;
}

.lessonBtn {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px; 
    letter-spacing: 0.04em; 
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 2px 10px;
    margin-top: 5px;
    color: #fff;
    background-clip: text;
    background: #2e2e2e;
}
.fc-event-main {
    font-style: normal;
}
.lessonBtn:hover {
    box-shadow: 0px 0px 15px rgba(66, 66, 66, 0.57);
    background: #101010;
}
.eventClass {
    font-family: 'Sora-Regular';
    height: 26px;
    font-size: 12px;
    word-wrap: break-word;
    padding: 4px 0 0px 2px;
    font-style: normal !important;
    cursor: pointer;
}

.schedule {
  padding: 20px;
  background-color: rgb(255, 255, 255) !important;
}

.scheduleButton {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px; 
    letter-spacing: 0.04em; 
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 6px;
    padding: 8px 10px;
    margin-right: 10px;
}

.btnActive {
  color: #fff;
  background-color: #101010;
}

.previewContainer {
  margin-top: 10px;
}

.preview {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.normal {
  background-color: rgb(1, 66, 176) !important;
}

.green {
    background-color: rgb(15, 15, 15) !important;
}

.gold {
    background-color: rgb(3, 161, 61) !important;
}

.mentor {
    background-color: rgb(255, 235, 60) !important;
}

.done {
  background-color: rgb(157, 157, 157) !important;
}

.mentor  b {
  color: #101010;
}

.MuiLinearProgress-root{
  height: 30px !important;
  background-color: rgb(229, 229, 229) !important;
}

.MuiLinearProgress-root .MuiLinearProgress-bar1Determinate{
  background-color: rgb(48, 111, 12);
}

.optional {
   background-color: rgb(36, 36, 36) !important;
}

.portfolio {
  /* background-color: rgb(132, 185, 0) !important; */
  background-color: rgb(1, 66, 176) !important;
}

.graded {
  animation: colorchange 2s infinite; 
}

@keyframes colorchange {
  0%   {background-color: rgb(199, 103, 0);}
  25%  {background-color: rgb(199, 90, 0);}
  50%  {background-color: rgb(199, 43, 0);}
  75%  {background-color: rgb(199, 43, 0);}
  100% {background-color: rgb(199, 103, 0);}
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  
  background: #f5f4ee;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
  font-family: 'Sora', sans-serif;
}

.demo-app-main {
  flex-grow: 1;
  padding: 1em;
  font-family: 'Sora-Regular';
}

.lazyText {
  margin-top: 20px;
}

.lazyText span{
  font-size: 16px !important;
  font-family: 'Sora-Regular', sans-serif;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.reset {
  position: relative;
  bottom: 20px;
  margin: 20px 20px 5px 0px ;
  color: white;
  background-color: rgb(209, 60, 34);
}

.previewHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #001eff;
  color: #fff;
}

.previewHeader a {
  color: #F8D210;
  padding: 0 5px;
  text-decoration: underline;
}